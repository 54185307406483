.language-menu {
  top: 4px;
  border-radius: 8px !important;
  box-shadow: 1px 2px 16px 0 #00000029 !important;

  > .mat-mdc-menu-content {
    padding: unset;
  }
}

.folders-menu {
  top: 4px;
  border-radius: 8px !important;
  box-shadow: 1px 2px 16px 0 #00000029 !important;
  text-align: right;

  > .mat-mdc-menu-content {
    padding: unset;

    .mat-mdc-menu-item {
      justify-content: flex-end !important;
    }
  }
}

.notification-menu {
  width: 560px !important;
  height: 460px !important;

  max-width: 560px !important;
  max-height: 460px !important;

  top: 8px;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08) !important;

  > .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    line-height: normal;
    height: 100%;

    padding: unset;
    h6 {
      margin: 0;
    }
  }
}

.export-menu {
  top: 4px;
  border-radius: 8px !important;
  box-shadow: 1px 2px 16px 0 #00000029 !important;
  margin-top: 9px;
  margin-left: -15px;

  > .mat-mdc-menu-content {
    padding: unset;
    p {
      margin-bottom: 0;
    }
  }
}

.dropdown-list {
  margin-top: 2px;
  padding: 0;
  box-shadow: 0 2px 24px 0 rgba(30, 84, 138, 0.16), 0 0 4px 0 rgba(30, 84, 138, 0.12);
  border-radius: 8px;

  a,
  a:hover {
    text-decoration: none !important;
    min-height: unset !important;
  }

  > .mat-mdc-menu-content {
    padding: 8px 0 0;
    > .mat-menu-content {
      padding: 0;
    }

    .mat-menu-item {
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.5;

      &:hover {
        background-color: var(--gray-palette-100);
      }
    }
  }
}
