:root {
  --live-search-folders-folder-favorite-icon: #ff9800;
  --live-search-folders-folder-data-icon: #3b98f5;
  --live-search-folders-folder-trash-icon: #ff4647;
  --live-search-folders-folder-text: #171717;
  --live-search-folders-hide-text: #3b98f5;
  --live-search-folders-folder-saved-search-icon: #3b98f5;
  --live-search-folders-folder-disabled: #d4d4d4;

  /* live search - public - start*/
  --live-search-folders-bg-panel-public: #f0f7ff;
  --live-search-folders-title-public: #004f9e;
  // switcher - start
  --live-search-folders-switcher-bg-public-active: #3b98f5;
  // switcher - end

  //forlders - forlder - start
  --live-search-folders-folder-section-title-public: #3b98f5;
  --live-search-folders-folder-bg-folder-public: #dbedff;
  --live-search-folders-folder-stroke-folder-public: #3b98f5;
  --live-search-folders-folder-actions-icon-public: #3b98f5;
  // forlders - forlder - end
  /* live search - public - end*/

  /* live search - construction - start*/
  --live-search-folders-bg-panel-construction: #f7f7f7;
  --live-search-folders-title-construction: #171717;

  // switcher - start
  --live-search-folders-switcher-bg-construction-active: #ff9800;
  // switcher - end

  //forlders - forlder - start
  --live-search-folders-folder-section-title-construction: #ff9800;
  --live-search-folders-folder-bg-folder-construction: #fcf0de;
  --live-search-folders-folder-stroke-folder-construction: #ff9800;
  --live-search-folders-folder-actions-icon-construction: #ff9800;
  //forlders - forlder - end
  /* live search - construction - end*/

  /* live search - forlders - switcher - start*/
  --live-search-folders-switcher-text-active: #ffffff;
  --live-search-folders-switcher-text-inactive: #393939;
  /* live search - forlders - switcher - end*/
}
