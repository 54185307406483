.mat-mdc-tab:not(.mat-mdc-tab-disabled) {
  &.mdc-tab--active {
    .mdc-tab__text-label {
      color: var(--tabs-underline-selected);
    }
  }
  .mdc-tab__text-label {
    color: var(--tabs-icon-text-enable);
  }
  .mdc-tab-indicator__content--underline {
    border-color: var(--tabs-underline-enable);
    opacity: 1;
    width: 98%;
  }
  .mat-ripple-element {
    background-color: transparent;
  }
}

.mdc-tab-indicator--active {
  .mdc-tab-indicator__content {
    border-color: var(--tabs-underline-selected) !important;
  }
}

.mat-mdc-tab-disabled {
  .mdc-tab-indicator__content--underline {
    border-color: var(--tabs-disabled);
    opacity: 1;
    width: 98%;
  }
  .mat-ripple-element {
    background-color: transparent;
  }
}

.mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mdc-tab__ripple::before {
  background-color: transparent;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled):hover {
  .mdc-tab__text-label {
    color: var(--tabs-icon-text-selected);
  }
}

.tab-icon {
  margin-right: 8px;
}

.long-text {
  white-space: break-spaces;
  width: 120px;
}
