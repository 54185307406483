:root {
  /* primary palette - start*/
  --primary-palette-50: #f0f7ff;
  --primary-palette-100: #dbedff;
  --primary-palette-200: #bfdfff;
  --primary-palette-300: #92c7fc;
  --primary-palette-400: #5facfa;
  --primary-palette-500: #3b98f5;
  --primary-palette-600: #2688eb;
  --primary-palette-700: #1c7ad9;
  --primary-palette-800: #004f9e;
  --primary-palette-900: #1e548a;
  --primary-palette-gradient: #1e548a;
  --primary-palette-contrast-100: var(--light-primary-text);
  --primary-palette-contrast-200: var(--light-primary-text);
  --primary-palette-contrast-300: var(--light-primary-text);
  --primary-palette-contrast-400: var(--light-primary-text);
  --primary-palette-contrast-500: var(--light-primary-text);
  --primary-palette-contrast-600: var(--light-primary-text);
  --primary-palette-contrast-700: var(--light-primary-text);
  --primary-palette-contrast-800: var(--light-primary-text);
  /* primary palette - start*/

  /* secondary palette - start*/

  --secondary-palette-50: #3b98f5;
  --secondary-palette-100: #3b98f5;
  --secondary-palette-200: #3b98f5;
  --secondary-palette-300: #3b98f5;
  --secondary-palette-400: #3b98f5;
  --secondary-palette-500: #3b98f5;
  --secondary-palette-600: #3b98f5;
  --secondary-palette-700: #3b98f5;
  --secondary-palette-800: #3b98f5;
  --secondary-palette-900: #3b98f5;
  --secondary-palette-contrast-100: var(--light-primary-text);

  /* secondary palette - end*/

  /* warn palette - start*/

  --warn-palette-50: #fef2f2;
  --warn-palette-100: #fee2e2;
  --warn-palette-200: #fecaca;
  --warn-palette-300: #fca5a5;
  --warn-palette-400: #f87171;
  --warn-palette-500: #ef4444;
  --warn-palette-600: #ff0000;
  --warn-palette-700: #d90b0b;
  --warn-palette-800: #ad0909;
  --warn-palette-900: #850000;
  --warn-palette-contrast-100: var(--light-primary-text);

  /* warn palette - end*/

  /* gray palette - start*/

  --gray-palette-50: #fafafa;
  --gray-palette-100: #f5f5f5;
  --gray-palette-200: #e5e5e5;
  --gray-palette-300: #d4d4d4;
  --gray-palette-400: #a3a3a3;
  --gray-palette-500: #737373;
  --gray-palette-600: #525252;
  --gray-palette-700: #404040;
  --gray-palette-800: #262626;
  --gray-palette-900: #171717;
  --gray-palette-contrast-100: var(--light-primary-text);

  /* gray palette - end*/

  /* additional palette - start*/

  --additional-palette-red: #ef4444;
  --additional-palette-orange: #ff9800;
  --additional-palette-green: #4caf50;
  --additional-palette-white: var(--light-primary-text);
  --additional-palette-black: var(--dark-primary-text);
  --additional-palette-100: var(--light-primary-text);
  --additional-palette-500: var(--light-primary-text);
  --additional-palette-700: var(--light-primary-text);
  --additional-palette-contrast-main: var(--light-primary-text);

  /* additional palette - end*/

  /* tag palette - start*/

  --tag-palette-1: #004f9e;
  --tag-palette-2: #9a2ab9;
  --tag-palette-3: #3b98f5;
  --tag-palette-4: #b82525;
  --tag-palette-5: #2b98ae;
  --tag-palette-6: #4d2ab9;
  --tag-palette-7: #4caf50;
  --tag-palette-8: #dd970c;
  --tag-palette-9: #098e62;
  --tag-palette-10: #cc6b0a;
  --tag-palette-11: #d3c313;
  --tag-palette-12: #1eae24;
  --tag-palette-13: #710c8d;
  --tag-palette-14: #006f85;
  --tag-palette-15: #226f55;
  --tag-palette-16: #0f6bd4;
  --tag-palette-17: #a76c31;
  --tag-palette-18: #2e0b97;
  --tag-palette-19: #8e1010;
  --tag-palette-20: #00376d;
  --tag-palette-21: #503986;
  --tag-palette-22: #375f94;
  --tag-palette-23: #098e09;
  --tag-palette-24: #527ab7;
  --tag-palette-25: #833d92;
  --tag-palette-26: #984949;
  --tag-palette-27: #518689;
  --tag-palette-28: #78a849;
  --tag-palette-29: #887100;
  --tag-palette-30: #b89536;
  --tag-palette-100: #b89536;
  --tag-palette-700: #b89536;
  --tag-palette-contrast-main: var(--light-primary-text);

  /* tag palette - end*/
}

$primary-palette: (
  50: #f0f7ff,
  100: #dbedff,
  200: #bfdfff,
  300: #92c7fc,
  400: #5facfa,
  500: #3b98f5,
  600: #2688eb,
  700: #1c7ad9,
  800: #004f9e,
  900: #1e548a,
  contrast: (
    100: var(--light-primary-text),
    200: var(--light-primary-text),
    300: var(--light-primary-text),
    400: var(--light-primary-text),
    500: var(--light-primary-text),
    600: var(--light-primary-text),
    700: var(--light-primary-text),
    800: var(--light-primary-text),
  ),
);

$secondary-palette: (
  50: #3b98f5,
  100: #3b98f5,
  200: #3b98f5,
  300: #3b98f5,
  400: #3b98f5,
  500: #3b98f5,
  600: #3b98f5,
  700: #3b98f5,
  800: #3b98f5,
  900: #3b98f5,
  contrast: (
    main: var(--light-primary-text),
  ),
);

$gray-palette: (
  50: #fafafa,
  100: #f5f5f5,
  200: #e5e5e5,
  300: #d4d4d4,
  400: #a3a3a3,
  500: #737373,
  600: #525252,
  700: #404040,
  800: #262626,
  900: #171717,
  contrast: (
    main: var(--light-primary-text),
  ),
);

$additional-palette: (
  red: #ef4444,
  orange: #ff9800,
  green: #4caf50,
  white: var(--light-primary-text),
  black: var(--dark-primary-text),
  100: var(--light-primary-text),
  500: var(--light-primary-text),
  700: var(--light-primary-text),
  contrast: (
    main: var(--light-primary-text),
  ),
);

$tag-palette: (
  1: #004f9e,
  2: #9a2ab9,
  3: #3b98f5,
  4: #b82525,
  5: #2b98ae,
  6: #4d2ab9,
  7: #4caf50,
  8: #dd970c,
  9: #098e62,
  10: #cc6b0a,
  11: #d3c313,
  12: #1eae24,
  13: #710c8d,
  14: #006f85,
  15: #226f55,
  16: #0f6bd4,
  17: #a76c31,
  18: #2e0b97,
  19: #8e1010,
  20: #00376d,
  21: #503986,
  22: #375f94,
  23: #098e09,
  24: #527ab7,
  25: #833d92,
  26: #984949,
  27: #518689,
  28: #78a849,
  29: #887100,
  30: #b89536,
  100: #b89536,
  700: #b89536,
  contrast: (
    main: var(--light-primary-text),
  ),
);
