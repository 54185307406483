@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

$ebp-typography-config: mat.m2-define-typography-config(
  $font-family: 'Lato',
  $headline-5: mat.m2-define-typography-level(96px, 100px, 400, $letter-spacing: -1.5px),
  $headline-6: mat.m2-define-typography-level(60px, 72px, 400, $letter-spacing: -0.5px),
  $subtitle-1: mat.m2-define-typography-level(48px, 60px, 400, $letter-spacing: 0),
  $body-1: mat.m2-define-typography-level(34px, 44px, 400, $letter-spacing: 0.25px),
  $button: mat.m2-define-typography-level(14px, 24px, 700, $letter-spacing: 1.25px),
  $caption: mat.m2-define-typography-level(12px, 16px, 400, $letter-spacing: 0.4px),
);

@mixin typography($font-weight, $font-size, $line-height, $letter-spacing) {
  font-weight: $font-weight !important;
  font-size: $font-size !important;
  line-height: $line-height !important;
  letter-spacing: $letter-spacing !important;
}

.mat-typography {
  h5 {
    @include typography(500, 24px, 32px, 0);
  }

  h6 {
    @include typography(700, 20px, 28px, 0.15px);
  }

  .subtitle-1 {
    @include typography(700, 16px, 24px, 0.15px);
  }

  .subtitle-2 {
    @include typography(700, 14px, 20px, 0.25px);
  }

  .body-1 {
    @include typography(400, 16px, 24px, 0.5px);
  }

  .body-2 {
    @include typography(400, 14px, 20px, 0.25px);
  }

  .caption {
    @include typography(400, 12px, 16px, 0.4px);
  }

  .caption-bold {
    @include typography(700, 12px, 16px, 0.4px);
  }

  .overline {
    @include typography(400, 10px, 14px, 1.5px);
    text-transform: uppercase;
  }
}
