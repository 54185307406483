@use '@angular/material' as mat;
@use '../theme/palette' as palette;

.mat-mdc-snack-bar-container {
  border-radius: 4px;

  &.app-notification-success {
    background: var(--toster-bg-confirm);
    --mdc-snackbar-container-color: var(--toster-bg-confirm);
  }

  &.app-notification-warning {
    background: var(--toster-bg-warning);
    --mdc-snackbar-container-color: var(--toster-bg-warning);
  }

  &.app-notification-danger {
    background: var(--toster-bg-error);
    --mdc-snackbar-container-color: var(--toster-bg-error);
  }

  &.app-notification-info {
    background: var(--gray-palette-900);
    --mdc-snackbar-container-color: var(--additional-palette-500);
    --mdc-snackbar-supporting-text-color: var(--additional-palette-500);
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
