:root {
  --message-text: #171717;

  /* message-info - start*/
  --message-bg-info: #f5f5f5;
  --message-icon-info: #3b98f5;
  /* message-info - end*/

  /* message-warning - start*/
  --message-bg-warning: #feecec;
  --message-icon-warning: #ff4647;
  /* message-warning - end*/
}
