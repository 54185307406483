:root {
  /* large button variables - start*/
  --large-button-width: fit-content;
  --large-button-height: 48px;
  --large-button-line-height: 24px;
  --large-button-letter-spacing: 1.25px;
  /* large button variables - end*/

  /* small button variables - start*/
  --small-button-width: fit-content;
  --small-button-height: 32px;
  --small-button-line-height: 20px;
  --small-button-letter-spacing: 0.25px;
  /* small button variables - end*/

  /* main color palette - start */
  --buttons-main-button-active: #004f9e;
  --buttons-main-button-hover: #1c7ad9;
  --buttons-main-button-pressed: #1e548a;
  --buttons-main-button-disabled: #d4d4d4;
  --buttons-main-button-icon-text-white: #ffffff;
  /* main color palette - end */

  /* secondary color palette - start */
  --buttons-secondary-button-active: #3b98f5;
  --buttons-secondary-button-hover: #1c7ad9;
  --buttons-secondary-button-pressed: #2688eb;
  --buttons-secondary-button-disabled: #d4d4d4;
  --buttons-secondary-button-icon-text-white: #ffffff;
  /* secondary color palette - end */

  /* accent color palette - start */
  --buttons-accent-button-active: #004f9e;
  --buttons-accent-button-hover: #1c7ad9;
  --buttons-accent-button-pressed: #1e548a;
  --buttons-accent-button-disabled: #d4d4d4;
  --buttons-accent-button-icon-text-white: #ffffff;
  /* accent color palette - end */

  /* warn color palette - start */
  --buttons-warning-button-active: #ff4647;
  --buttons-warning-button-hover: #eb4041;
  --buttons-warning-button-pressed: #ff4647;
  --buttons-warning-button-disabled: #d4d4d4;
  --buttons-warning-button-icon-text-white: #ffffff;
  /* warn color palette - end */
}
