:root {
  --buttons-round-btn-bg-enable: #dbedff;
  --buttons-round-btn-bg-hover: #004f9e;

  --buttons-round-btn-notification-bg: #ff4647;
  --buttons-round-btn-notification-text: #ffffff;

  --buttons-round-btn-icon-enable: #004f9e;
  --buttons-round-btn-icon-hover: #f0f7ff;
}
