:root {
  --general-components-bg-shadow: #1e548a;
  --general-components-bg-white: #ffffff;
  --general-components-bg-colored: #f0f7ff;
  --general-components-border: #bfdfff;
  --general-components-line: #bfdfff;

  --general-table-line: #dbedff;

  --additional-white: #fff;
}
