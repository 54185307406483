@use '@angular/material' as mat;
@use '../theme/palette' as palette;

.mdc-switch__icon.mdc-switch__icon--on,
.mdc-switch__icon.mdc-switch__icon--off {
  display: none;
}
.mdc-switch__handle::before {
  display: none !important;
}
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  width: 16px !important;
  height: 16px !important;
  &::after {
    background: var(--light-primary-text) !important;
  }
  .mdc-switch__shadow {
    box-shadow: 0 0 2px rgb(0 0 0 / 20%) !important;
    left: 2px !important;
    height: 16px !important;
    width: 16px !important;
  }
}
.mat-mdc-slide-toggle .mdc-switch {
  width: 40px !important;
  .mdc-switch__track {
    border-radius: 10px !important;
    height: 20px !important;
  }
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--checked .mdc-switch__handle {
  &::after,
  .mdc-switch__shadow {
    left: 0 !important;
  }
}
.mdc-switch__handle::after {
  left: 2px !important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track {
  &::before {
    background: mat.m2-get-color-from-palette(palette.$gray-palette, 400) !important;
  }
  &::after {
    background: mat.m2-get-color-from-palette(palette.$primary-palette, 700) !important;
  }
}
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: calc(100% - 18px) !important;
}

// disabled case
.mat-mdc-slide-toggle .mdc-switch:disabled {
  .mdc-switch__track {
    opacity: 1 !important;
    &::before,
    &::after {
      background: mat.m2-get-color-from-palette(palette.$gray-palette, 300) !important;
    }
  }
  .mdc-switch__handle::after {
    opacity: 0.5 !important;
  }
}
