::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--scrollbar-bg-color);
}
::-webkit-scrollbar-thumb {
  border: 2px solid var(--scrollbar-bg-color);
  border-radius: 10px;
  background: var(--scrollbar-slider);
}
