:root {
  --project-favorite-icon-enable: #ff9800;
  --project-favorite-icon-disabled: #d4d4d4;

  --project-list-view-devider: #e5e5e5;

  /* project - filters - start */
  --project-filters-group-title: #004f9e;
  --project-filters-section-title-enable: #393939;
  --project-filters-section-title-active: #3b98f5;
  --project-filters-group-underline: #dbedff;
  --project-filters-bg-filter-box: #f7f7f7;
  --project-filters-chevron: #3b98f5;
  --project-filters-tree-level-line: #dbedff;
  --project-filters-text-search-matches: #3b98f5;

  /* project - filters - date range switcher - start */
  --project-filters-date-range-switcher-text-active: #ffffff;
  --project-filters-date-range-switcher-text-inactive: #393939;
  --project-filters-date-range-switcher-bg-active: #3b98f5;
  --project-filters-date-range-switcher-stroke: #3b98f5;
  /* project - filters - date range switcher - end */

  /* project - filters - slider - start */
  --project-filters-slider-bg-flag: #004f9e;
  --project-filters-slider-text-flag: #ffffff;
  --project-filters-slider-bg-date-point: #3b98f5;
  --project-filters-slider-stroke-date-point: #ffffff;
  --project-filters-slider-bg-timeline: #e5e5e5;
  --project-filters-slider-bg-timeline-frame: #bfdfff;
  /* project - filters - slider - end */
  /* project - filters - end  */

  /* project - labels - start */
  --project-labels-text-icons-enable: #171717;
  --project-labels-text-icons-active: #ffffff;
  --project-labels-color-1: #004f9e;
  --project-labels-color-2: #9a2ab9;
  --project-labels-color-3: #3b98f5;
  --project-labels-color-4: #b82525;
  --project-labels-color-5: #2b98ae;
  --project-labels-color-6: #4d2ab9;
  --project-labels-color-7: #4caf50;
  --project-labels-color-8: #dd970c;
  --project-labels-color-9: #098e62;
  --project-labels-color-10: #cc6b0a;
  --project-labels-color-11: #d3c313;
  --project-labels-color-12: #1eae24;
  --project-labels-color-13: #710c8d;
  --project-labels-color-14: #006f85;
  --project-labels-color-15: #226f55;
  --project-labels-color-16: #0f6bd4;
  --project-labels-color-17: #a76c31;
  --project-labels-color-18: #2e0b97;
  --project-labels-color-19: #8e1010;
  --project-labels-color-20: #00376d;
  --project-labels-color-21: #503986;
  --project-labels-color-22: #375f94;
  --project-labels-color-23: #098e09;
  --project-labels-color-24: #527ab7;
  --project-labels-color-25: #833d92;
  --project-labels-color-26: #984949;
  --project-labels-color-27: #518689;
  --project-labels-color-28: #78a849;
  --project-labels-color-29: #887100;
  --project-labels-color-30: #b89536;
  /* project - labels - end */

  /* project - details - toolbar - start */
  --project-details-toolbar-bg: #dbedff;
  --project-details-toolbar-bg-constr: #f5f5f5;
  /* project - details - toolbar - end */

  /* project - details - available - start */
  --project-details-available-button-bg: #3b98f5;
  --project-details-available-button-text: #fff;
  /* project - details - available - end */

  /* unread palette - start*/
  --unread-gradient-construction: var(--Gradients-EBP-Construction-Unread, linear-gradient(90deg, #fffaf2 0%, rgba(255, 250, 242, 0) 20%));
  --unread-border-color-construction: var(--List-view-Line-for-unread-Constr, #ff9800);
  --unread-gradient-public: var(--Gradients-EBP-Public-Unread, linear-gradient(90deg, #f0f7ff 0%, rgba(240, 247, 255, 0) 20%));
  --unread-border-color-public: var(--List-view-Line-for-unread-Public, #3b98f5);
  /* unread palette - end*/
}
