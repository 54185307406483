.mat-mdc-checkbox label {
  color: var(--checkbox-label);
}

.mat-mdc-checkbox span.highlight {
  color: var(--secondary-palette-500) !important;
}

.mdc-checkbox__background {
  border: 1px solid var(--checkbox-enable) !important;
  border-radius: 5px !important;
  width: 20px !important;
  height: 20px !important;
  top: calc((var(--mdc-checkbox-state-layer-size) - 20px) / 2) !important;
  left: calc((var(--mdc-checkbox-state-layer-size) - 20px) / 2) !important;
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled .mdc-checkbox__background {
  border-color: var(--checkbox-disabled) !important;
}

.error-case .mdc-checkbox__background {
  border-color: var(--checkbox-error) !important;
}

// Checked case with checkmark
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: var(--checkbox-selected) !important;
  border-color: var(--checkbox-selected) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background {
  background-color: var(--checkbox-disabled) !important;
}

.mdc-checkbox__checkmark {
  color: var(--checkbox-bg-fill) !important;
  width: 12px !important;
  margin: auto !important;
}

// Intermifiate case with mixedmark
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: var(--checkbox-selected) !important;
  background-color: var(--checkbox-bg-fill) !important;
  display: flex;
  align-items: center;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background {
  background-color: var(--checkbox-bg-fill) !important;
  display: flex;
  align-items: center;
}

.mdc-checkbox__mixedmark {
  border-radius: 2px !important;
  border-width: 2px !important;
  border-color: var(--checkbox-selected) !important;
  width: 10px !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: var(--checkbox-disabled) !important;
}

// Hover
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: var(--checkbox-selected);
}

.mdc-checkbox__background {
  background-color: var(--primary-palette-50) !important;
}

.checkbox-list-container {
  mat-checkbox {
    width: 100%;
    --mdc-checkbox-state-layer-size: 24px !important;

    .mdc-form-field {
      display: flex !important;
      justify-content: space-between !important;

      .mdc-label {
        margin-left: 0 !important;
      }
    }
  }
}
