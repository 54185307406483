:root {
  /* Links-main - start*/
  --links-main-enable: #3b98f5;
  --links-main-hover: #004f9e;
  --links-main-pressed: #3b98f5;
  /* Links-main - end*/

  /* Links-accent - start*/
  --links-accent-enable: #3b98f5;
  --links-accent-hover: #004f9e;
  --links-accent-pressed: #3b98f5;
  /* Links-accent - end*/

  /* Links-black - start*/
  --links-black-enable: #171717;
  --links-black-hover: #3b98f5;
  --links-black-pressed: #004f9e;
  /* Links-black - end*/
}
