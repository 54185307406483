:root {
  --project-list-view-text-title: #171717;
  --project-list-view-text-body: #393939;
  --project-list-view-icon-dce-docs: #3b98f5;
  --project-list-view-matched-keyword-dce-docs: #fef5a8;
  --project-list-view-bg-quick-search-bar: #f7f7f7;
  --project-list-view-list-message-text: #a3a3a3a3;
  --project-list-view-list-message-icon: #bfdfff;

  /* public - start*/
  --project-list-view-line-for-unread-public: #3b98f5;
  /* public - end*/

  /* construction - start*/
  --project-list-view-line-for-unread-constr: #ff9800;
  /* construction - end*/
}
