.my-account-navbar-section {
  display: flex;
  height: 100%;
  border-right: 1px solid var(--primary-palette-200);

  ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 24px;
      cursor: pointer;

      &:not(.active):hover {
        color: var(--secondary-palette-900);
      }
    }
  }
  .active {
    color: var(--primary-palette-800);
    border-right: 2px solid var(--primary-palette-800);
  }
}
