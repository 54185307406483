:root {
  --project-details-timeline-stage-bg-enable: #ffffff;
  --project-details-timeline-stage-bg-selected-public: #3b98f5;
  --project-details-timeline-stage-bg-selected-construction: #171717;
  --project-details-timeline-stage-bg-disable: #e5e5e5;
  --project-details-timeline-stage-connect-filled-public: #3b98f5;
  --project-details-timeline-stage-connect-filled-construction: #171717;
  --project-details-timeline-stage-connect: #e5e5e5;
  --project-details-timeline-bg-public: #1e548a;
  --project-details-timeline-bg-construction: #fcd292;
  --project-details-timeline-icon-enable-public: #3b98f5;
  --project-details-timeline-icon-enable-construction: #ff9800;
  --project-details-timeline-icon-selected: #ffffff;
  --project-details-timeline-icon-disable: #a3a3a3;
  --project-details-timeline-icon-canceled: #ffffff;
  --project-details-timeline-border-current-stage-public: #3b98f5;
  --project-details-timeline-close-icon-public: #ffffff;
  --project-details-timeline-close-icon-construction: #171717;
  --project-details-timeline-stage-bg-canceled: #ff4647;
  --project-details-timeline-updates-bg: #4caf50;
  --project-details-timeline-updates-number: #ffffff;
}
