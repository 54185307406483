:root {
  --backoffice-brand-chip-main: #004f9e;
  --backoffice-brand-chip-secondary-1: #3b98f5;
  --backoffice-brand-chip-secondary-2: #ff9800;
  --backoffice-brand-chip-secondary-3: #171717;
  --backoffice-brand-chip-bg-main: #f0f7ff;
  --backoffice-brand-chip-bg-secondary-1: #f0f7ff;
  --backoffice-brand-chip-bg-secondary-2: #fffaf2;
  --backoffice-brand-chip-bg-secondary-3: #f7f7f7;
}
