.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio .mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--radiobutton-selected) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  width: 8px;
  height: 8px;
  border-width: 8px;
  top: 2px;
  left: 2px;
}
