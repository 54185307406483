:root {
  --project-details-contact-box-accordion-chevron: #3b98f5;
  --project-details-contact-box-line: #bfdfff;
  --project-details-contact-box-border: #bfdfff;
  --project-details-contact-box-chevron: #3b98f5;
  --project-details-contact-box-chevron-active: #ffffff;
  --project-details-contact-box-contact-name: #3b98f5;
  --project-details-contact-box-icon: #92c7fc;
  --text-styles-body: #393939;
  --text-styles-heading: #171717;
}
