:root {
  /* text-style - start*/
  --text-styles-heading: #171717;
  --text-styles-body: #393939;
  --text-styles-hint: #a3a3a3;
  --text-styles-icon: #92c7fc;
  --text-styles-bullets: #5facfa;
  --text-styles-title: #3B98f5;
  /* text-style - end*/
}
