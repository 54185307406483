@use '@angular/material' as mat;
@use '../theme/palette' as palette;

.loader-content {
  width: -webkit-fill-available;
  height: 86%;
  background: var(--additional-palette-white);
  opacity: 0.8;
  position: fixed;
  z-index: 1000;

  .data-loader {
    width: 56px;
    height: 56px;
    top: 30%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: var(--primary-palette-500);
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
  }

  .data-loader::after,
  .data-loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 28px;
    height: 28px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color:var(--primary-palette-500);
    border-radius: 50%;
    animation: animloader 1.5s infinite ease-in-out;
  }

  .data-loader::before {
    background-color:var(--primary-palette-800);
    transform: scale(0.5) translate(-56px, -56px);
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animloader {
    50% {
      transform: scale(1) translate(-50%, -50%);
    }
  }
}
