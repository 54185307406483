:root {
  --project-list-view-pagination-dots: #3b98f5;
  --project-list-view-pagination-bg-panel: #ffffff;

  --project-list-view-pagination-bg-selected: #3b98f5;
  --project-list-view-pagination-bg-enable: #ffffff;
  --project-list-view-pagination-bg-hover: #f0f7ff;

  --project-list-view-pagination-stroke-enable: #3b98f5;
  --project-list-view-pagination-stroke-disabled: #d4d4d4;
  --project-list-view-pagination-stroke-top-panel: #bfdfff;

  --project-list-view-pagination-icon-text-enable: #3b98f5;
  --project-list-view-pagination-icon-text-selected: #ffffff;
  --project-list-view-pagination-icon-text-disabled: #d4d4d4;
}
