.mat-button-toggle-group {
  height: 32px;
  border-radius: var(--border-radius);
  width: 160px;

  .mat-button-toggle {
    border-left: none;
    width: 50%;
  }

  .mat-button-toggle-appearance-standard {
    background: transparent;
  }

  .mat-button-toggle-label-content {
    display: flex;
    justify-content: center;
    line-height: 32px;
  }

  .mat-button-toggle-checked {
    background: var(--secondary-palette-900);
    border-radius: 6px;
    .mat-button-toggle-label-content {
      color: var(--light-primary-text);
    }
  }
}
