:root {
  --notifications-icon-in-circle-bg: #f5f5f5;

  --notifications-icon-in-circle-share-icon: #3B98F5;
  --notifications-icon-in-circle-public-icon: #004F9E;
  --notifications-icon-in-circle-construction-icon: #FF9800;


  --notifications-icon-in-circle-other-icons: #171717;
}
