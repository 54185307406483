button {
  white-space: nowrap;
  &.large {
    text-transform: uppercase !important;
  }
  &.small {
    text-transform: capitalize !important;
  }

  .mdc-button__label {
    display: flex;
    align-items: center;
  }
}

button.mat-mdc-unelevated-button {
  &.mat-primary {
    background: var(--buttons-main-button-active);
    color: var(--buttons-main-button-icon-text-white);
  }

  &.mat-accent {
    background: var(--buttons-secondary-button-active);
    color: var(--buttons-secondary-button-icon-text-white);
  }

  &.mat-customAccent {
    background: var(--buttons-accent-button-active);
    color: var(--buttons-accent-button-icon-text-white);
  }

  &.mat-warn {
    background: var(--buttons-warning-button-active);
    color: var(--buttons-warning-button-icon-text-white);
  }
}

button.mat-mdc-unelevated-button:active {
  &.mat-primary {
    background: var(--buttons-main-button-pressed) !important;
    color: var(--buttons-main-button-icon-text-white);
  }

  &.mat-accent {
    background: var(--buttons-secondary-button-pressed) !important;
    color: var(--buttons-secondary-button-icon-text-white);
  }

  &.mat-customAccent {
    background: var(--buttons-accent-button-pressed) !important;
    color: var(--buttons-accent-button-icon-text-white);
  }

  &.mat-warn {
    background: var(--buttons-warning-button-pressed) !important;
    color: var(--buttons-warning-button-icon-text-white);
  }
}

button.mat-mdc-unelevated-button:hover {
  &.mat-primary {
    background: var(--buttons-main-button-hover);
    color: var(--buttons-main-button-icon-text-white);
  }

  &.mat-accent {
    background: var(--buttons-secondary-button-hover);
    color: var(--buttons-secondary-button-icon-text-white);
  }

  &.mat-customAccent {
    background: var(--buttons-accent-button-hover);
    color: var(--buttons-accent-button-icon-text-white);
  }

  &.mat-warn {
    background: var(--buttons-warning-button-hover);
    color: var(--buttons-warning-button-icon-text-white);
  }
}

button.mat-mdc-outlined-button:hover {
  &.mat-primary {
    border: 1px solid var(--buttons-main-button-hover) !important;
    color: var(--buttons-main-button-icon-text-white) !important;
    background: var(--buttons-main-button-hover);
  }

  &.mat-accent {
    border: 1px solid var(--buttons-secondary-button-hover) !important;
    color: var(--buttons-secondary-button-icon-text-white) !important;
    background: var(--buttons-secondary-button-hover);
  }

  &.mat-customAccent {
    border: 1px solid var(--buttons-accent-button-hover) !important;
    color: var(--buttons-accent-button-icon-text-white) !important;
    background: var(--buttons-accent-button-hover);
  }
}

button.mat-mdc-outlined-button:not(:disabled) {
  &.mat-primary {
    border: 1px solid var(--buttons-main-button-active);
    color: var(--buttons-main-button-active);
  }

  &.mat-accent {
    border: 1px solid var(--buttons-secondary-button-active);
    color: var(--buttons-secondary-button-active);
  }

  &.mat-customAccent {
    border: 1px solid var(--buttons-accent-button-active);
    color: var(--buttons-accent-button-active);
  }
}

button.mat-mdc-unelevated-button,
button.mat-mdc-outlined-button {
  border-radius: 7px;

  &.large {
    height: 48px;
    line-height: 24px;
    letter-spacing: 1.25px;
  }

  &.small {
    height: 32px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &.large,
  &.small {
    span.mdc-button__label {
      font-size: 14px;
    }
  }

  &.mat-primary {
    color: var(--buttons-main-button-icon-text-white);
  }

  &.mat-accent {
    color: var(--buttons-secondary-button-icon-text-white);
  }

  &.mat-customAccent {
    color: var(--buttons-accent-button-icon-text-white);
  }
}

button.mat-mdc-unelevated-button:disabled {
  &.mat-primary {
    background: var(--buttons-main-button-disabled);
    color: var(--buttons-main-button-icon-text-white);
  }

  &.mat-accent {
    background: var(--buttons-secondary-button-disabled);
    color: var(--buttons-secondary-button-icon-text-white);
  }

  &.mat-customAccent {
    background: var(--buttons-accent-button-disabled);
    color: var(--buttons-accent-button-icon-text-white);
  }

  &.mat-warn {
    background: var(--buttons-warning-button-disabled);
    color: var(--buttons-warning-button-icon-text-white);
  }
}

button.mat-mdc-outlined-button:disabled {
  &.mat-primary {
    border: 1px solid var(--buttons-main-button-disabled);
    color: var(--buttons-main-button-disabled);
  }

  &.mat-accent {
    border: 1px solid var(--buttons-secondary-button-disabled);
    color: var(--buttons-secondary-button-disabled);
  }

  &.mat-customAccent {
    border: 1px solid var(--buttons-accent-button-disabled);
    color: var(--buttons-accent-button-disabled);
  }
}

// spinner displayed in button
.button-loading {
  span,
  mat-icon {
    display: none !important;
  }

  .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}
