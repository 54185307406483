:root {
  /* large input variables - start*/
  --large-input-width: 100%;
  --large-input-height: 48px;
  --large-input-font-size: 16px;
  /* large input variables - end*/

  /* small input variables - start*/
  --small-input-width: 100%;
  --small-input-height: 32px;
  --small-input-font-size: 12px;
  /* small input variables - end*/

  /* inputs-selector - start*/
  --input-selector-bg-white: #ffffff;
  --input-selector-bg-gray: #f5f5f5;

  --input-selector-text-hint: #a3a3a3;
  --input-selector-text-filled: #393939;
  --input-selector-text-error: #ff4647;

  --input-selector-icon-enable: #bfdfff;
  --input-selector-text-icon-disable: #d4d4d4;

  --input-selector-border-enable: #bfdfff;
  --input-selector-border-active: #5facfa;
  --input-selector-border-disable: #d4d4d4;
  --input-selector-border-error: #ff4647;

  --input-selector-chevron: #3b98f5;
  --input-selector-stepper: #fff;
  --input-selector-cursor: #3b98f5;

  --input-selector-text-search-type: #3b98f5;
  --input-selector-search-type-disabled: #ffffff;

  /* inputs-selector - end*/

  /* dropdown-list - start*/
  --input-selector-dropdown-list-text-enable: #393939;
  --input-selector-dropdown-list-text-hover: #3b98f5;
  --input-selector-dropdown-list-text-disable: #d4d4d4;
  --input-selector-dropdown-list-captcha: #a3a3a3;
  --input-selector-dropdown-list-user-name: #3b98f5;
  --input-selector-dropdown-list-bg: #ffffff;
  /* dropdown-list - end*/

  //this is for select placed in input-with-selector
  --input-selector-select-value: #3b98f5;
  --input-selector-select-bg-disabled: #d4d4d4;
  --input-selector-select-bg: #f5f5f5;
  --input-selector-text-disalbed: #fff;
}
