:root{
  --project-list-view-timeline-icon-next-stage: #D4D4D4;
  --project-list-view-timeline-divider: #D4D4D4;

  /* public - start */
  --project-list-view-timeline-icon-prev-stage-public: #92C7FC;
  --project-list-view-timeline-icon-current-stage-public: #3B98F5;
  /* public - end */

  /* construction - start */
  --project-list-view-timeline-icon-prev-stage-construction: #FFBF61;
  --project-list-view-timeline-icon-current-stage-construction: #FF9800;
  --project-list-view-timeline-icon-cancelled-stage-construction: #FF4647;
  /* construction - end */
}
