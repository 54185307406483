a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: var(--links-accent-hover);
  }

  &.link-login {
    color: var(--links-accent-enable);
  }
}
