:root {
  --text-styles-body: #393939;

  // profile Avatar
  --my-account-profile-avatar-text: #004f9e;
  --my-account-profile-avatar-bg: #dbedff;

  // Navigation
  --my-account-navigation-text-enabled: #393939;
  --my-account-navigation-text-hover-selected: #004f9e;
  --my-account-navigation-bg-scroll: #bfdfff;
  --my-account-navigation-selected-point: #004f9e;

  // Update message
  --my-account-update-message-text: #171717;
  --my-account-update-message-bg: #ffffff;
  --my-account-update-message-border: #bfdfff;

  // Customer contacts
  --my-account-customer-bg: #ffffff;
  --my-account-customer-icon: #92C7FC;
}
